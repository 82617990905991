import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	tableCellClasses,
} from '@mui/material'
import { ReactComponent as CheckupIcon } from 'assets/checkup-icon.svg'
import { ReactComponent as ArrowNext } from 'assets/fill_back_arrow.svg'
import { ReactComponent as TagNumberIcon } from 'assets/icons/tag-icon.svg'
import { getYearsOld, morphForWords } from 'components/shared/processes'
import { ICowsList } from 'models/ICattlesData'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchDiagnosesList } from 'store/cattlesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { COLORS } from 'styles/colors'
import TableSkeleton from 'ui/skeleton/TableSkeleton'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { TypographySecondaryBold16 } from 'ui/typography/TypographySecondaryBold16'
import CheckupCowModal from './checkupCowModal/CheckupCowModal'
import AddColumnMenu from './filterElements/AddColumnMenu'
import { ColumnTypes } from './models'
import { getTranslationStatus, initialActiveColumn } from './processes'
type LamenesTableProps = {
	cattlesInfo: ICowsList[]
	fetchingCowsList: boolean
}
const LamenessTable = (props: LamenesTableProps) => {
	const { cattlesInfo, fetchingCowsList } = props
	const [columnActiveFilter, setColumnActiveFilter] =
		useState<ColumnTypes[]>(initialActiveColumn)
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [cowInfo, setCowInfo] = useState<ICowsList>(undefined)
	const dispatch = useAppDispatch()
	const { diagnosesList, diagnosesListPending } = useAppSelector(
		state => state.cattlesSlice
	)

	useEffect(() => {
		if (diagnosesList.length === 0) {
			dispatch(fetchDiagnosesList())
		}
	}, [])
	const handleActiveColumnFilter = (title: string) => {
		setColumnActiveFilter(prevState => {
			return prevState.map(column => {
				if (column.title === title) {
					return { ...column, isShowColumn: !column.isShowColumn }
				}
				return column
			})
		})
	}
	const handleOpenCheckupModal = (el: ICowsList) => {
		setCowInfo(el)
		setOpenModal(true)
	}
	const navigate = useNavigate()
	const navigateCowPasportPage = (tagId, id) => {
		navigate(`/cattles/cow/${tagId}`, { state: { cowId: id } })
		window.scrollTo(0, 0)
	}
	return (
		<TableContainer
			sx={{
				marginTop: '4px',
				overflowY: 'auto',
				// overflowX: 'auto',
				height: 'calc(100vh - 350px)',
				'&::-webkit-scrollbar': {
					width: 4,
					height: 4,
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: '#DBDBDB',
					borderRadius: 12,
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#5222D0',
					borderRadius: 12,
				},
			}}
		>
			<Table>
				<TableHead
					sx={{
						[`& .${tableCellClasses.root}`]: {
							backgroundColor: '#fff',
						},
					}}
				>
					<TableRow sx={{ width: '100%', height: '50px' }}>
						<TableCell
							align={'left'}
							padding={'none'}
							// width={'5%'}
							sx={{
								minWidth: '85px',
								position: 'sticky',
								left: 0,
								background: 'white',
							}}
						>
							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								gap={'4px'}
							>
								<TagNumberIcon />
								<TypographySecondary>
									Номер
									<br />
									бирки
								</TypographySecondary>
							</Box>
						</TableCell>
						<TableCell
							align={'left'}
							padding={'none'}
							// width={'5%'}
							sx={{
								minWidth: '160px',
								position: 'sticky',
								left: 80,
								background: 'white',
							}}
						>
							<TypographySecondary>
								{' '}
								Хромота, балл
								<br />
								Продолжительность
							</TypographySecondary>
						</TableCell>
						{columnActiveFilter[0].isShowColumn && (
							<TableCell
								align={'left'}
								padding={'none'}
								// width={'5%'}
								sx={{ minWidth: '100px' }}
							>
								<TypographySecondary>
									День
									<br />
									лактации
								</TypographySecondary>
							</TableCell>
						)}
						{columnActiveFilter[1].isShowColumn && (
							<TableCell
								align={'left'}
								padding={'none'}
								// width={'10%'}
								sx={{ minWidth: '220px' }}
							>
								<TypographySecondary>Диагноз</TypographySecondary>
							</TableCell>
						)}
						{columnActiveFilter[2].isShowColumn && (
							<TableCell
								align={'left'}
								padding={'none'}
								// width={'10%'}
								sx={{ minWidth: '160px' }}
							>
								<TypographySecondary>Статус лечения </TypographySecondary>
							</TableCell>
						)}
						{columnActiveFilter[3].isShowColumn && (
							<TableCell
								align={'left'}
								padding={'none'}
								// width={'10%'}
								sx={{ minWidth: '146px' }}
							>
								<TypographySecondary>Статус осмотра</TypographySecondary>
							</TableCell>
						)}
						{columnActiveFilter[4].isShowColumn && (
							<TableCell
								align={'left'}
								padding={'none'}
								// width={'5%'}
								sx={{ minWidth: '146px' }}
							>
								<TypographySecondary>Возраст коровы</TypographySecondary>
							</TableCell>
						)}
						{columnActiveFilter[5].isShowColumn && (
							<TableCell
								align={'left'}
								padding={'none'}
								// width={'5%'}
								sx={{ minWidth: '180px' }}
							>
								<TypographySecondary>Группа</TypographySecondary>
							</TableCell>
						)}
						{columnActiveFilter[6].isShowColumn && (
							<TableCell
								align={'left'}
								padding={'none'}
								// width={'5%'}
								sx={{ minWidth: '146px' }}
							>
								<TypographySecondary>Статус по лактации</TypographySecondary>
							</TableCell>
						)}
						{columnActiveFilter[7].isShowColumn && (
							<TableCell
								align={'left'}
								padding={'none'}
								// width={'5%'}
								sx={{ minWidth: '146px' }}
							>
								<TypographySecondary>Упитанность, балл</TypographySecondary>
							</TableCell>
						)}
						{columnActiveFilter[8].isShowColumn && (
							<TableCell
								align={'left'}
								padding={'none'}
								// width={'5%'}
								sx={{ minWidth: '180px' }}
							>
								<TypographySecondary>Сотрудник</TypographySecondary>
							</TableCell>
						)}

						<TableCell
							align={'left'}
							padding={'none'}
							// width={'5%'}
							sx={{
								minWidth: '113px',
								position: 'sticky',
								right: 0,
								background: 'white',
								zindex: 4,
							}}
						>
							<Box
								display={'flex'}
								flexDirection={'row'}
								justifyContent={'flex-end'}
								alignItems={'center'}
							>
								<TypographySecondary>Действия</TypographySecondary>
								<AddColumnMenu
									onClick={handleActiveColumnFilter}
									activeColumns={columnActiveFilter}
								/>
							</Box>
						</TableCell>
					</TableRow>
				</TableHead>
				{fetchingCowsList || diagnosesListPending ? (
					<TableBody>
						<TableRow>
							<TableCell colSpan={12} align={'center'} padding={'none'}>
								<TableSkeleton
									widthForBody={'100%'}
									heightForBody={'34px'}
									rowCount={36}
									totalHeight={'calc(100vh - 350px)'}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				) : cattlesInfo.length === 0 ? (
					<TableBody>
						<TableRow
							sx={{
								width: '100%',
								[`& .${tableCellClasses.root}`]: {
									borderBottom: 'none',
									height: '300px',
								},
							}}
						>
							<TableCell colSpan={12} align={'center'} padding={'none'}>
								<TypographySecondaryBold16>
									Нет данных
								</TypographySecondaryBold16>
							</TableCell>
						</TableRow>
					</TableBody>
				) : (
					<TableBody>
						{cattlesInfo?.map((el, index) => (
							<TableRow
								key={index}
								sx={{
									height: '46px',
									// '&:hover': {
									// 	background: COLORS.mainSecondaryOpacity,
									// },
									[`& .${tableCellClasses.root}`]: {
										borderBottom: 'none',
									},
								}}
							>
								<TableCell
									align={'left'}
									padding={'none'}
									sx={{
										verticalAlign: 'top',
										padding: '8px 0px',
										minWidth: '85px',
										position: 'sticky',
										left: 0,
										background: 'white',
									}}
								>
									<TypographyPrimary>{el.tag_id}</TypographyPrimary>
								</TableCell>
								<TableCell
									align={'left'}
									padding={'none'}
									sx={{
										verticalAlign: 'top',
										padding: '8px 0px',
										minWidth: '160px',
										position: 'sticky',
										left: 80,
										background: 'white',
									}}
								>
									{el.limpness && (
										<TypographyPrimary>
											{el.limpness}{' '}
											<span
												style={{ fontSize: '12px', color: COLORS.secondary }}
											>
												{morphForWords(el.limpness, [
													'балл',
													'балла',
													'баллов',
												])}{' '}
												<br /> {el.limpness_length ? el.limpness_length : '-'}
											</span>
										</TypographyPrimary>
									)}
								</TableCell>
								{columnActiveFilter[0].isShowColumn && (
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										<TypographySecondary14>
											{el.lactation_length +
												' ' +
												morphForWords(el.lactation_length, [
													'день',
													'дня',
													'дней',
												])}
										</TypographySecondary14>
									</TableCell>
								)}
								{columnActiveFilter[1].isShowColumn && (
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										<Box display={'flex'} flexDirection={'column'} gap={'16px'}>
											{el.diagnosis?.map((diagnose, index) => (
												<Box key={index}>
													<TypographyPrimary>
														{diagnose.sickness
															.map(el => {
																const findTitleOfSickness = diagnosesList.find(
																	diagnose => diagnose.id === el.id
																)?.title
																return findTitleOfSickness
															})
															.filter(Boolean)
															.join(', ')}
													</TypographyPrimary>
													{diagnose.position && (
														<TypographySecondary>
															{getTranslationStatus(diagnose.position)}
														</TypographySecondary>
													)}
												</Box>
											))}
										</Box>
									</TableCell>
								)}
								{columnActiveFilter[2].isShowColumn && (
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										{el.treatment_status && (
											<TypographyPrimary>
												{getTranslationStatus(el.treatment_status)}
											</TypographyPrimary>
										)}
										{el.treatment_length && (
											<TypographySecondary>
												{el.treatment_length}
											</TypographySecondary>
										)}
									</TableCell>
								)}
								{columnActiveFilter[3].isShowColumn && (
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										{el.examination_status && (
											<>
												<TypographyPrimary sx={{ color: el.treatment_color }}>
													{getTranslationStatus(el.examination_status)}
												</TypographyPrimary>
												<TypographySecondary sx={{ color: el.treatment_color }}>
													{moment(el.next_examination_date).format(
														'DD.MM.YYYY'
													)}
												</TypographySecondary>
											</>
										)}
									</TableCell>
								)}
								{columnActiveFilter[4].isShowColumn && (
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										<TypographyPrimary>
											{getYearsOld(el.birth_date)}
										</TypographyPrimary>
									</TableCell>
								)}
								{columnActiveFilter[5].isShowColumn && (
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										<TypographyPrimary>{el.group.title}</TypographyPrimary>
									</TableCell>
								)}
								{columnActiveFilter[6].isShowColumn && (
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										<TypographyPrimary>{el.lactation_state}</TypographyPrimary>
									</TableCell>
								)}
								{columnActiveFilter[7].isShowColumn && (
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										{el.fatness && (
											<TypographyPrimary>
												{el.fatness +
													' ' +
													morphForWords(el.fatness, [
														'балл',
														'балла',
														'баллов',
													])}
											</TypographyPrimary>
										)}
									</TableCell>
								)}
								{columnActiveFilter[8].isShowColumn && (
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										<TypographyPrimary>{el.examiner}</TypographyPrimary>
									</TableCell>
								)}

								<TableCell
									align={'right'}
									padding={'none'}
									sx={{
										verticalAlign: 'top',
										padding: '8px 0px',
										position: 'sticky',
										right: 0,
										background: 'white',
									}}
								>
									<Box
										display={'flex'}
										flexDirection={'row'}
										alignItems={'center'}
										gap={'16px'}
										justifyContent={'end'}
										mr={'30px'}
									>
										<Box onClick={() => handleOpenCheckupModal(el)}>
											<CheckupIcon
												stroke={COLORS.secondaryFont}
												style={{ cursor: 'pointer' }}
											/>
										</Box>
										<ArrowNext
											onClick={() => navigateCowPasportPage(el.tag_id, el.id)}
											style={{
												cursor: 'pointer',
												transform: 'rotate(180deg)',
											}}
											stroke={COLORS.secondary}
										/>
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				)}
			</Table>
			{openModal && (
				<CheckupCowModal
					openModal={openModal}
					cowInfo={cowInfo}
					onClose={() => setOpenModal(false)}
				/>
			)}
		</TableContainer>
	)
}

export default LamenessTable
